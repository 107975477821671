export default class OrgTypeTableItem {
    orgTypeId = 0;
    orgTypeStatus = 0;
    orgTypeSortId = 0;
    orgTypeName = "";
    orgTypeRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
