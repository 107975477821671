import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import OrgTypeTableItem from "@/entity/system/master/OrgTypeTableItem";
import API from "@/api/system/master/org-type";

@Options({
    name: "app-system-master-org-type",
    components: {
        "app-draggable-listview": DraggableListview,
    },
})
export default class OrgType extends BaseTableMixins<OrgTypeTableItem> implements Partial<BaseTableInterface<OrgTypeTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new OrgTypeTableItem();
        this.editModalItem = new OrgTypeTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<OrgTypeTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new OrgTypeTableItem();
        this.editModalItem = new OrgTypeTableItem();
        this.editModalItem.orgTypeStatus = 1;
        this.editModalTitle = "添加组织类型";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgTypeName)) return "类型名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgTypeStatus)) return "类型状态不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改组织类型 (" + this.editModalItem.orgTypeName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgTypeId)) return "类型ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除组织类型",
            message: "组织类型名称: " + this.editModalItem.orgTypeName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgTypeId)) return "类型ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "组织类型排序";
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "组织类型状态",
            message: "组织类型: " + this.editModalItem.orgTypeName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.orgTypeStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.orgTypeStatus = this.editModalItem.orgTypeStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgTypeId)) return "类型ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }
}
